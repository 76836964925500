<template lang="pug">
div
  b-row.m-0.justify-content-center
    b-col.content-header(cols='9')
      h2.content-header-title.float-left.m-0.pr-1.mr-1
        | {{ $route.meta.pageTitle }}
      .content-header-search.mr-1
        h3.m-0
          | {{
          | (customerForm.civility == 0
          | ? &quot;M. &quot;
          | : customerForm.civility == 1
          | ? &quot;Mme &quot;
          | : customerForm.civility == 3
          | ? &quot;Société &quot;
          | : &quot;&quot;) +
          | customerForm.firstName +
          | &quot; &quot; +
          | customerForm.lastName
          | }}
      .content-header-actions.d-flex
        feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
  .content-loader-center.m-0.h-100(v-if='isLoadingCustomer || isUpdatingCustomer || isCreatingCustomer')
    .text-center.flex-center
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des d&eacute;tails du client...
  b-row.content-scrollable-sticky.m-0.h-100.justify-content-center(v-else)
    b-col.content-scrollable-sticky-main(cols='9')
      validation-observer(ref='formCreateCustomer')
        b-row.my-0
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Civilité *' label-for='Civility')
              validation-provider(#default='{ errors }' name='Civility' rules='required')
                v-select#civility(:state='errors.length > 0 ? false : null' :reduce='type => type.value' v-model='customerForm.civility' :options='optionCivility')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='4')
            b-form-group(:label="customerForm.civility == 3 ? 'Désignation' : 'Prénom'" label-for='firstName')
              b-form-input#firstName(v-model='customerForm.firstName')
          b-col.py-0.mb-1(cols='4')
            b-form-group(:label="customerForm.civility == 3 ? 'Nom du contact *' : 'Nom *'" label-for='lastName')
              validation-provider(#default='{ errors }' name='lastName' rules='required')
                b-form-input#lastName(v-model='customerForm.lastName' :state='errors.length > 0 ? false : null')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Date du début du contrat *' label-for='contractDate')
              validation-provider(#default='{ errors }' name='contractDate' rules='required')
                date-range-picker.w-100( :state='errors.length > 0 ? false : null' ref="picker" id="contractDate" :show-dropdowns="true" :single-date-picker="true" :show-week-numbers="true" :timePicker="false" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="filterRange" :auto-apply="true" )
                  template(v-slot:input="picker")
                    | {{ formatDate(filterRange.startDate) }}
                  small.text-danger {{ errors[0] }}
        vs-divider.mt-0.mb-1(color='#cccccc') Adresse
        b-row.my-0
          b-col.py-0(cols='12')
            b-form-group(label='Adresse *' label-for='address')
              validation-provider(#default='{ errors }' name='address' rules='required')
                vue-typeahead-bootstrap#address.mb-1.not-autocomplete(type='text' :state='errors.length > 0 ? false : null' v-model='customerForm.address' :ieCloseFix='false' :data='autocompleteAddressData' :serializer='(item) => item.properties.label' :showAllResults='true' @input='autocompleteAddressSource()' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                  template(slot='suggestion' slot-scope='{ data, htmlText }')
                    .item
                      small {{ data.properties.name }}
                      span {{ data.properties.postcode + ' ' + data.properties.city }}
                  small.text-danger {{ errors[0] }}
          b-col.py-0(cols='3')
            b-form-group(label='Code postal *' label-for='postalCode')
              validation-provider(#default='{ errors }' name='postalCode' rules='required')
                b-form-input#postalCode(v-model='customerForm.postalCode' autocomplete='nope' aria-autocomplete='nope' :state='errors.length > 0 ? false : null')
                small.text-danger {{ errors[0] }}
          b-col.py-0(cols='9')
            b-form-group(label='Ville *' label-for='city')
              validation-provider(#default='{ errors }' name='city' rules='required')
                b-form-input#city(v-model='customerForm.city' autocomplete='nope' aria-autocomplete='nope' :state='errors.length > 0 ? false : null')
                small.text-danger {{  errors[0] }}
        b-row.my-0
          b-col.py-0.mb-50.mt-50(cols='12' :class="displayGps ? 'mb-50' : 'mb-1'")
            small.cursor-pointer.text-primary(@click="displayGps = !displayGps") {{ displayGps ? '- Masquer les coordonnées GPS' : '+ Editer les coordonnées GPS' }} 
        b-row.my-0(v-if="displayGps")
          b-col.py-0(cols='12')
            label(for="address'") Coordonn&eacute;es GPS
          b-col.py-0(cols='3')
            validation-provider(#default='{ errors }' name='latitude' rules='required')
              b-form-input#latitude(v-model='customerForm.lat' placeholder='Latitude' :state='errors.length > 0 ? false : null')
              small.text-danger {{  errors[0] }}
          b-col.py-0(cols='3')
            validation-provider(#default='{ errors }' name='longitude' rules='required')
              b-form-input#longitude(v-model='customerForm.long' placeholder='Longitude' :state='errors.length > 0 ? false : null')
              small.text-danger {{  errors[0] }}
        vs-divider.mt-1.mb-1(color='#cccccc') Coordonnées
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='E-mail' label-for='email')
              validation-provider(#default='{ errors }' name='email' rules='email')
                b-form-input#email(v-model='customerForm.email' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Téléphone principal' label-for='phoneNumber')
              b-form-input#phoneNumber(v-model='customerForm.tel1' v-mask="'## ## ## ## ##'" autocomplete='nope' aria-autocomplete='nope')
        vs-divider.mt-1.mb-1(color='#cccccc') Second contact
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Prénom' label-for='firstName')
              b-form-input#firstName(v-model='customerForm.secondFirstName')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Nom' label-for='lastName')
                b-form-input#lastName(v-model='customerForm.secondLastName')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Civilité' label-for='Civility')
                v-select#civility( :reduce='type => type.value' v-model='customerForm.secondCivility' :options='optionCivility')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Téléphone secondaire' label-for='phoneNumber')
              b-form-input#phoneNumber(v-model='customerForm.tel2' v-mask="'## ## ## ## ##'" autocomplete='nope' aria-autocomplete='nope')
        vs-divider.mt-1.mb-1(color='#cccccc') Contact gardien
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Prénom' label-for='firstName')
              b-form-input#firstName(v-model='customerForm.conciergeFirstName')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Nom *' label-for='lastName')
                b-form-input#lastName(v-model='customerForm.conciergeLastName')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Civilité *' label-for='Civility')
                v-select#civility( :reduce='type => type.value' v-model='customerForm.conciergeCivility' :options='optionCivility')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Téléphone gardien' label-for='telConcierge')
              b-form-input#telConcierge(v-model='customerForm.telConcierge' v-mask="'## ## ## ## ##'" autocomplete='nope' aria-autocomplete='nope')
      b-row.my-0
        b-col.py-0(cols='12')
          .d-flex.justify-content-between.mt-2(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem')
            div
              b-button(variant='outline-primary' @click='cancel()')
                feather-icon(icon='ArrowLeftIcon')
                  | Annuler
            b-button(variant='primary' @click='create')
              feather-icon(icon='SaveIcon' v-if='customerForm.id')
              feather-icon(icon='PlusIcon' v-else)
              | {{ customerForm.id ? &quot;Modifier&quot; : &quot;Ajouter&quot; }}
</template>

<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Ripple from "vue-ripple-directive";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

const moment = require("moment");
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

const config = {
  direction: "ltr",
  format: "dd/mm/yyyy HH:MM",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  firstDay: 1,
};

let today = new Date();

let yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

var defaultRange = {
  "Aujourd'hui": [today, today],
  Hier: [yesterday, yesterday],
};

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayGps: false,
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
        {
          label: ECivility[ECivility.Société],
          value: ECivility.Société,
        },
      ],
      required,
      url,
      email,
      config: config,
      defaultRange: defaultRange,
      filterRange: {},
      dataOrigine: {},
    };
  },
  async created() {
    this.popupCustomerEvent(this.id);
    this.setInputDate();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    autocompleteAddressSource: debounce(function (indexSource) {
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${this.customerForm.address}&autocomplete=0&limit=50`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.customerForm.address = item.properties.name;
      this.customerForm.city = item.properties.city;
      this.customerForm.postalCode = item.properties.postcode;
      this.customerForm.lat = item.geometry.coordinates[1];
      this.customerForm.long = item.geometry.coordinates[0];
    },

    popupCustomerEvent(id) {
      if (id != "") {
        this.fetchCustomer(id).then((res) => {
          this.customerForm = res;
          this.dataOrigine = JSON.stringify(this.customerForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      (this.customerForm = {
        id: 0,
        civility: null,
        lastName: "",
        city: "",
        postalCode: "",
        address: "",
        lat: null,
        long: null,
        firstName: "",
        tel1: "",
        tel2: "",
        email: "",
        telConcierge: "",
        contractCreationDate: "",
      }),
        (this.dataOrigine = JSON.stringify(this.customerForm));
    },
    setInputDate() {
      if (this.customerForm.id) {
        this.filterRange = {
          startDate: new Date(this.customerForm.contractCreationDate),
          endDate: null,
        };
      } else {
        this.filterRange = {
          startDate: new Date(),
          endDate: null,
        };
      }
    },
    formatDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const dayFormat = day < 10 ? `0${day}` : day;
      const monthFormat = month < 10 ? `0${month}` : month;

      return `${dayFormat}-${monthFormat}-${year}`;
    },
    ...mapActions(["createCustomer", "updateCustomer", "fetchCustomer", "archiveCustomer"]),
    create(e) {
      e.preventDefault();
      this.$refs.formCreateCustomer
        .validate()
        .then((success1) => {
          if (success1) {
            const contractDate = moment(this.filterRange.startDate).format("YYYY-MM-DD HH:mm:ss");
            if (this.customerForm.id)
              this.updateCustomer({
                id: this.customerForm.id,
                contractCreationDate: contractDate,
                civility: this.customerForm.civility,
                lastName: this.customerForm.lastName,
                city: this.customerForm.city,
                postalCode: this.customerForm.postalCode,
                address: this.customerForm.address,
                lat: this.customerForm.lat,
                long: this.customerForm.long,
                firstName: this.customerForm.firstName,
                tel1: this.customerForm.tel1,
                tel2: this.customerForm.tel2,
                email: this.customerForm.email,
                telConcierge: this.customerForm.telConcierge,
                secondFirstName: this.customerForm.secondFirstName,
                secondLastName: this.customerForm.secondLastName,
                secondCivility: this.customerForm.secondCivility,
                conciergeCivility: this.customerForm.conciergeCivility,
                conciergeLastName: this.customerForm.conciergeLastName,
                conciergeFirstName: this.customerForm.conciergeFirstName,
              });
            else
              this.createCustomer({
                contractCreationDate: contractDate,
                civility: this.customerForm.civility,
                lastName: this.customerForm.lastName,
                city: this.customerForm.city,
                postalCode: this.customerForm.postalCode,
                address: this.customerForm.address,
                lat: this.customerForm.lat,
                long: this.customerForm.long,
                firstName: this.customerForm.firstName,
                tel1: this.customerForm.tel1,
                tel2: this.customerForm.tel2,
                email: this.customerForm.email,
                telConcierge: this.customerForm.telConcierge,
                secondFirstName: this.customerForm.secondFirstName,
                secondLastName: this.customerForm.secondLastName,
                secondCivility: this.customerForm.secondCivility,
                conciergeCivility: this.customerForm.conciergeCivility,
                conciergeLastName: this.customerForm.conciergeLastName,
                conciergeFirstName: this.customerForm.conciergeFirstName,
              });
            this.$nextTick(() => {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close({ to: "/directory/customers" });
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    archiveCustomerLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.", {
          title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveCustomer(id);
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },

    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.customerForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title: "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters(["isLoadingCustomer", "isUpdatingCustomer", "isCreatingCustomer", "customersTypesList", "customersList"]),
    customerForm: {
      get() {
        return this.$store.getters.customer;
      },
      set(value) {
        return this.$store.commit("SET_CUSTOMER", value);
      },
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    VSwatches,
    Autocomplete,
    VueTypeaheadBootstrap,
    DateRangePicker,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
